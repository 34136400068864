.foodie-message {
  display: flex;
  word-break: break-word;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  margin-right: 20%;

  &__content {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px 20px;
  }

  &__sender {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    text-align: left;
  }

  &__status-icon {
    margin-left: 5px;

    &--error {
      color: red;
    }
  }
}
