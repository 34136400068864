.collab-chat-item {
  padding: 20px 20px 16px 20px;
  border-radius: 8px;
  outline: 1px solid #e6e6e6;
  position: relative;

  &.active {
    outline: 2px solid #ffac39;
  }

  &__dropdown {
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
      background-color: #f0f0f0;
    }

    &-trigger {
      transform: rotate(90deg);
      cursor: pointer;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  &__participant-col {
    display: flex;
    gap: 12px;
    align-items: center;
    overflow: hidden;
    word-break: break-word;
  }

  &__participant-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;
    gap: 4px;
  }

  &__participant-name {
    font-size: 16px;
    font-weight: 600;
  }

  &__context-info {
    display: flex;
    flex-direction: column;
  }

  &__context-item {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;

    &--booking {
      gap: 6px;
    }

    .icon {
      width: 14px;
      height: 14px;

      path {
        stroke: #1d2023;
        stroke-width: 2;
      }
    }
  }

  &__venue-address {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .icon {
      width: 14px;
      height: 14px;
      vertical-align: text-bottom;
      margin-right: 4px;

      path {
        stroke: rgb(255, 172, 57);
      }
    }
  }

  &__collab-date {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .icon {
      width: 14px;
      height: 14px;
      vertical-align: text-bottom;
      margin-right: 4px;

      path {
        stroke: rgb(255, 172, 57);
        stroke-width: 2;
      }
    }
  }

  &__message-col {
    overflow: hidden;
    flex: 1;
    padding-top: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 4px;
    // max-width: 341px;
    max-width: 100%;
  }

  &__message-content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    font-size: 12px;
    color: #999999;
  }

  &__badge {
    margin-top: -3px;
  }

  &__arrow-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24px;
  }
}
