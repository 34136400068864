.chat {
  display: flex;

  &.--loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &.--mobile {
    width: 100%;

    & .chat__header-avatars {
      display: flex;
    }

    & .chat__header {
      display: flex;
      flex-direction: row;
      align-items: center;

      & .chat__header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &.--loading {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .chat__card {
      height: 100%;

      .chat__messages {
        .chat__messages-container {
        }
      }
    }
  }

  &__card {
    display: grid;
    grid-template-rows: 72px 1fr 91px;
    height: 550px;
    width: 100%;

    &--loading {
      grid-template-rows: 1fr auto;
    }
  }

  &__header {
    display: flex;
    flex: 1;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;

    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    padding-left: 12px;

    &-content {
      display: grid;
      grid-template-columns: 70px 1fr;
      flex-direction: row;
      flex: 1;
      align-items: center;
      gap: 12px;
    }

    &-avatars {
      display: flex;

      .mustard {
        .ant-avatar {
          object-fit: contain;
          background: white;
          border: 1px solid #e0e0e0;

          img {
            object-fit: contain;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    &-back {
      cursor: pointer;
    }

    &-name {
      color: #1d2023;
      font-size: 16px;
      font-weight: 500;
    }

    &__context-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-width: calc(100% - 40px);
    }

    &__context-item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      max-width: calc(100% - 20px);

      &--booking {
        white-space: nowrap;
      }

      .icon {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        margin-top: -2px;

        path {
          stroke: #ffac39;
        }
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__venue-address {
      font-size: 12px;
    }

    &-names {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
  }

  &__messages {
    display: flex;
    overflow-y: scroll;
    flex: 1;
    border-left: 1px solid #e5e5e5;

    &-container {
      padding: 20px 20px 0 20px;
      width: 100%;
    }
  }

  &__input {
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 100;

    &-container {
      padding: 10px 10px 0 12px;
      border-left: 1px solid #e5e5e5;
    }

    &-textarea {
      padding-right: 150px;
      height: 100px;
    }

    &-actions {
      position: relative;
    }

    &-buttons {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    &-send {
      color: white;
      height: 50px;

      &-icon {
        transform: rotate(-45deg);
      }
    }

    &-select {
      width: 55px;
      margin-left: 10px;

      &-dropdown {
        margin: 0;
        padding: 0;

        .avatar {
          display: flex;
          align-items: flex-start;

          .ant-avatar {
            object-fit: contain;
            background: transparent;
            border: 1px solid #e0e0e0;

            img {
              object-fit: contain;
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .avatar {
    display: flex;
    align-items: flex-start;

    .ant-avatar {
      object-fit: contain;
      background: transparent;
      border: 1px solid #e0e0e0;

      img {
        object-fit: contain;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
