.messenger {
  &.--mobile {
    .messenger__container {
      margin-bottom: 50px;
      .messenger__chat-container {
        grid-template-columns: 1fr;
        max-height: 100%;

        .messenger__chat-grid {
          height: 100%;
          max-height: 100%;
          overflow: auto;
        }
      }
    }
  }

  &__container {
    height: 100%;
    overflow: hidden;
  }

  &__card {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__empty {
    margin-bottom: 20px;

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__chat-container {
    display: grid;
    grid-template-columns: minmax(116px, 35%) 1fr;
    max-height: 600px;
  }

  &__chat-grid {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 550px;
    overflow: hidden;
    padding: 8px;
    overflow-y: auto;
  }

  &__chat-details {
    height: 550px;
  }
}
