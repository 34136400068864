.action-message {
  display: flex;
  word-break: break-word;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  margin-right: 20%;

  &.right {
    flex-direction: row-reverse;
    margin-right: 0;
    margin-left: 20%;
  }

  &__avatar {
    display: flex;
    align-items: flex-start;

    .ant-avatar {
      object-fit: contain;
      background: transparent;
      border: 1px solid #e0e0e0;

      img {
        object-fit: contain;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #e0e0e0;
    position: relative;
    flex: 1;
  }

  &__actions {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
  }

  &__sender {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    text-align: left;
  }

  &__confirmation-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 10;
  }

  &__confirmation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    padding: 20px;

    &-text {
      font-size: 14px;
      color: #1d2023;
    }

    &-buttons {
      display: flex;
      gap: 8px;
    }
  }
}
