.collabs {
  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #ffdeb0;
    background-color: #fff;
    height: 60px;
    width: 60px;
    position: relative;
    z-index: 1;

    .month {
      padding: 2px 4px;
      background: #ffdeb0;
      width: 100%;
      border-radius: 14px 14px 0 0;
      text-align: center;
      color: #1d2023;
      text-overflow: ellipsis;
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 175% */
      text-transform: uppercase;
    }
    .day {
      color: #1d2023;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .extra-info {
    display: flex;
    align-items: center;
    background-color: #ede8dd;
    border-radius: 0 0 16px 16px;
    margin-top: -12px;
    padding: 24px 30px 12px 30px;
    text-align: center;
    height: 50px;

    .content {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
        align-items: center;
      }
    }
  }

  .image-post {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: 60px;
    width: 40px;
  }

  .ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.collab {
  .ant-form-item {
    .ant-form-item-row {
      .ant-form-item-label {
        padding: 0;
      }
    }
  }
  .label-form {
    display: block;
  }
  .extra-label {
    display: block;
    color: #666;
    margin-bottom: 8px;
  }
}
