$orange: #ff7f50;
$highlight: #ffa500; // Cor de destaque para o card selecionado
$card-width: 300px;
.pricing {
  .promotion-badge-container {
    position: absolute;
    top: 10px;
    z-index: 1;
    width: 120px;
    height: 120px;
    left: 50%;
    transform: translateX(calc(-50% - 320px));
    .promotion-badge {
      background: #fef5e6;
      color: #ff8c00;
      font-weight: bold;
      font-size: 12px;
      padding: 5px 10px;
      border: 2px solid #ff8c00;
      border-radius: 50%;
      width: 124px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      transform: rotate(-20deg);
    }
  }

  .toggle-container {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    font-weight: 700;

    span {
      flex: 1;
      display: flex;
    }
  }
  .pricing-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    position: relative;
  }

  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: $card-width;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
    cursor: pointer;
    transition: border 0.1s;

    &.card-selected {
      border: 2px solid $highlight;
    }

    .title-container {
      display: flex;
      justify-content: center;
      .plan-title {
        font-size: 16px;
        color: #fff;
        background-color: $orange;
        border-radius: 16px;
        font-weight: 400;
        margin-bottom: 10px;
        padding: 4px 16px;
      }
    }

    .plan-name {
      font-size: 22px;
      color: #333;
      margin: 10px 0;
    }

    .plan-price {
      font-size: 20px;
      color: #000;
      margin: 15px 0;
    }

    .divider {
      height: 2px;
      background-color: $orange;
      margin: 20px 0;
    }

    .plan-details {
      list-style: none;
      padding: 0;

      .plan-detail-item {
        font-size: 16px;
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: start;

        .check-mark {
          width: 16px;
          height: 16px;
          background-color: $orange;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;

          &:after {
            content: "\2713"; // Código Unicode do check
            display: block;
            text-align: center;
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .black-hover {
      padding: 17px 30px;
      font-size: 16px;
      font-weight: 700;
      min-width: 400px;
      min-height: 66px;
      &:hover {
        &:disabled {
          color: #e8f1f9de;
        }
        background-color: #000;
      }
    }
  }
}
