.businesses-modal {
  .businesses {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 500px;
    overflow: auto;
    .business-item {
      border: 1px solid #e6e6e6;
      padding: 12px;
      border-radius: 6px;
      cursor: pointer;
      &.selected {
        border: 1px solid #ffac39;
      }
    }
  }
}
