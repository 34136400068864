.business-message {
  display: flex;
  word-break: break-word;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  margin-left: 20%;

  &.right {
    flex-direction: row-reverse;
    margin-right: 0;
    margin-left: 20%;
  }

  &.left {
    flex-direction: row;
    margin-right: 20%;
    margin-left: 0;
  }

  &__content {
    background-color: #ffac39;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px 20px;
  }

  &__avatar {
    display: flex;
    align-items: flex-start;

    .ant-avatar {
      object-fit: contain;
      background: transparent;
      border: 1px solid #e0e0e0;

      img {
        object-fit: contain;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__sender {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    text-align: left;
  }
}
