.ant-tag {
  padding: 8px 16px;
}

.tooltip-info {
  color: #999;
  .icon-tooltip {
    width: 12px;
    height: 12px;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collab-segmented {
  &.ant-segmented {
    padding: 0;
    .ant-segmented-group {
      .ant-segmented-item {
        border-radius: 8px 8px 0 0;
      }
    }
  }
}

.diamond {
  height: 20px;
  width: 20px;
  background-color: #ffac39;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: #ede8dd;
  .ant-menu {
    &.ant-menu-root {
      height: 100%;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item {
      height: 42px;
      line-height: 42px;
      width: 100%;
      margin: 5px 0;
      display: flex;
      align-items: center;
      color: #1d2023;
      &.ant-menu-item-selected {
        color: #1d2023;
        svg {
          path {
            stroke: #ffac39;
          }
        }

        .fill-only {
          path {
            stroke: none;
            fill: #ffac39;
          }
        }
      }
    }
  }
  &.ant-layout-sider-collapsed {
    .language-select {
      display: flex;
      justify-content: center;
      padding: 0;
      .ant-select-selector {
        padding: 0 12px;
      }
    }

    .header-top {
      justify-content: center;
      padding: 0;
    }

    .ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item {
        display: block;
      }
    }
  }
  .menu-top {
    height: 64px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .item {
      flex: 0 0 33.3333%;
      display: flex;

      &.last {
        justify-content: right;
      }

      &.center {
        justify-content: center;
      }
    }
  }
  &.content-menu {
    height: 100vh;
    width: 100%;
    position: fixed;
    transition: height 0.2s;
    background-color: #ede8dd;
    z-index: 999999;
    &.collapsed {
      height: 64px;
    }

    .vertical-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
  }
}

.ant-dropdown {
  &.menu-principal {
    top: 67px !important;
  }
}

.__floater__body {
  font-family: Inter;
}
