.settings {
  .business-info {
    .ant-upload-wrapper {
      width: 100%;
      display: flex;
      .ant-upload {
        .upload-avatar {
          display: flex;
          justify-content: center;
          margin: 40px 0;
          position: relative;
          .icon-remove {
            position: absolute;
            right: 0;
            top: 0;
            background: #fff;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
          }
        }
        &.ant-upload-select {
          flex: 1;
          .upload {
            padding: 32px 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 6px;
            border: 1px dashed #ccc;
            background: #fafafa;

            .text {
              font-size: 14px;
              color: #1d2023;
              margin-top: 8px;
            }

            svg {
              path {
                stroke: #ffac39;
              }
            }
          }
        }
      }
    }
    h2 {
      &.title {
        color: var(--Gray-90, #1d2023);
        /* H2 */
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }
    }
    .ant-btn {
      color: var(--Gray-90, #1d2023);
      /* Label Strong */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      padding: 14px 32px;
      min-width: 180px;
    }

    .ant-form-item {
      margin-bottom: 32px;
    }
  }

  .locations {
    &.edit-form {
      .ant-card-body {
        display: flex;
        justify-content: center;
      }
    }
    .content {
      display: flex;
      align-items: center;
      gap: 12px;
      .ant-typography {
        margin: 0;
        &h3 {
          font-size: 16px;
        }
      }
    }
    .pocs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        margin: 0;
        color: #1d2023;
        font-size: 14px;
        font-weight: 500;
        font-weight: 500;
        margin-right: 12px;
      }
      .other {
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .poc-description {
      display: flex;
      gap: 6px;
      color: #999999;
      .name {
        color: #1d2023;
      }
    }
    .link-social {
      a {
        color: #1d2023;
      }
    }
  }

  .subscription {
    .ant-card-body {
      padding: 40px;
    }

    h3.ant-typography {
      font-size: 20px;
    }
    .ant-typography {
      font-family: Poppins;
    }
    .card-invoice {
      .ant-card-body {
        .invoice-date {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        font-family: Inter;
        padding: 24px 40px;
      }
    }
    .box-info {
      display: flex;
      flex-direction: column;
      span {
        svg {
          path {
            stroke: #ffac39;
          }
        }
      }
    }
    .payment-exp {
      color: #999;
      font-size: 16px;
      font-weight: 400;
    }

    .invoice-format {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      svg {
        cursor: pointer;
      }
    }
  }

  .domain {
    .disclaimer {
      padding: 12px 16px;
      border-radius: 6px;
      background: #f7f7f7;
    }
  }

  h1 {
    &.ant-typography {
      color: var(--Gray-90, #1d2023);
      /* H1 */
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
    }
  }
  span {
    &.ant-typography {
      color: var(--Gray-90, #1d2023);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
      &.value {
        font-weight: 400;
      }
    }
  }

  .ant-col {
    &.ant-form-item-label {
      label {
        color: var(--Gray-90, #1d2023);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
      }
    }
  }
}
