.header-1 {
  color: var(--Gray-90, #1d2023);
  /* H1 */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.header-2 {
  color: var(--Gray-90, #1d2023);
  /* H2 */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 130% */
}

.label-strong {
  color: var(--Gray-90, #1d2023);
  /* Label Strong */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.label-regular-strong {
  color: var(--Gray-90, #1d2023);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.text-regular {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.text-regular-small {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.text-regular-medium {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.text-strong {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.text-strong-small {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.text-strong-large {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.text-uppercase-small {
  color: var(--Gray-90, #1d2023);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  text-transform: uppercase;
}

.text-stronger-extra-small {
  overflow: hidden;
  color: var(--Gray-90, #1d2023);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px; /* 125% */
  text-transform: uppercase;
}
