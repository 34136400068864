.influencers {
  .influencer-card {
    // cursor: pointer;
    width: 290px;
  }

  .venue {
    background-color: #ede8dd;
    border-radius: 16px 16px 0 0;
    margin-bottom: -12px;
    padding: 12px 30px 24px 30px;
    text-align: center;
    height: 50px;
    ///ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.display {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    svg {
      width: 12px;
      height: 12px;
    }
  }
  .content {
    background-color: white;
    border-radius: 16px;
    padding: 24px;

    .home-applications {
      flex: 3;
    }
    .social {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      .attributes {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: #999999;
        font-size: 13px;
      }

      .low {
        path {
          fill: #ff354a;
        }
      }
      .medium {
        path {
          fill: #ffac39;
        }
      }
      .high {
        path {
          fill: #07b22d;
        }
      }
    }

    .avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-avatar {
        &.premium {
          border: 2px solid #ffac39;
        }
      }
      .tag {
        padding: 4px 8px;
        border-radius: 14px;
        display: block;
        border: 1px solid #e6e6e6;
        background: #fff;
        margin-top: -8px;
        position: relative;
        &.premium {
          border: 1px solid #ffac39;
          background: #ffac39;
          color: #1d2023;
        }
      }
    }

    .name {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      margin-top: 16px;
      text-align: center;
      gap: 4px;
      cursor: pointer;
    }

    .image-post {
      width: 100%;
      border-radius: 8px;
      object-fit: cover;
      height: 110px;
    }

    .diamond {
      height: 20px;
      width: 20px;
      background-color: #ffac39;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      font-size: 12px;
    }
  }

  .accept {
    &.ant-btn {
      transition: 0s color;
      gap: 0;
      padding: 8px 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50px;
      &:hover .countdown {
        .ant-statistic-content-value {
          color: #fff;
          // transition-delay: 0.1s;
        }
        svg {
          path {
            stroke: #fff;
            // transition-delay: 0.1s;
          }
        }
      }
    }
    .countdown {
      &:hover {
        .ant-statistic-content-value {
          color: #fff;
        }
      }
      .ant-statistic-content,
      .ant-statistic-content-prefix {
        display: flex;
        align-items: center;
        height: 20px;
      }
      .ant-statistic-content-value {
        font-size: 12px;
      }
    }
  }

  .button-clear {
    padding: 6px 12px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.05);
    border: none;
  }
}

.segmented-mobile {
  &.ant-segmented {
    .ant-segmented-group {
      overflow: auto;
    }
  }
}
