.onboarding {
  background-color: #f9f4e9;
  min-height: 100dvh;
  // padding: 20px;
  display: flex;
  &.web {
    padding: 0;
    .content {
      margin: 70px;
    }

    div {
      &.ant-typography {
        color: var(--Gray-800, #1d2023);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
    h2 {
      &.ant-typography {
        color: var(--Gray-800, #1d2023);
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .checkbox {
      &.ant-checkbox-wrapper {
        span:last-child {
          color: var(--Gray-800, #1d2023);
          text-align: center;
          /* Desktop/Caption L */
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 112.5% */
        }
      }
    }
    .ant-btn-primary,
    .ant-btn {
      padding: 23px 80px;
      color: var(--Gray-800, #1d2023);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .box-social {
      padding: 22px 20px;
      gap: 24px;
      .title {
        font-size: 16px;
      }
      .content-social {
        gap: 12px;
      }
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }
  .bg-images {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .ant-btn-primary {
      padding: 0 80px;
    }
    .ant-btn-text {
      color: var(--Gray-800, #1d2023);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      padding: 0;
    }
  }
  .content,
  .bg-images {
    flex: 1;
  }

  .underline-span {
    cursor: pointer;
    text-decoration: underline;
    margin: 0 5px;
    font-weight: 700;
  }

  .social-media-input {
    display: flex;
    align-items: center;
    gap: 12px;
    .ant-form-item {
      flex: 1;
    }
    svg {
      cursor: pointer;
      margin-bottom: 24px;
    }
  }

  .checkbox {
    &.ant-checkbox-wrapper {
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      padding: 24px 20px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        &::after {
          top: 10px;
          width: 8px;
          height: 13px;
        }
      }
      span:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
      }
    }
  }

  .instagram-content {
    display: flex;
    justify-content: center;
    gap: 10px;
    .container {
      padding: 22px 20px;
      border-radius: 10px;
      border: 1px solid #a5a6a7;
      display: flex;
      flex-direction: column;
      gap: 20px;
      span {
        color: #1d2023;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
      img {
        width: 100%;
      }
    }
  }
  .box-social {
    padding: 12px;
    border-radius: 10px;
    // border: 1px solid #a5a6a7;
    box-shadow: 4px 4px 8px 4px #a5a6a740;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    width: calc(100% - 36px);
    .title {
      font-family: Inter;
      font-size: 14px;
      &.bold {
        font-weight: 700;
      }
    }
    .content-social {
      display: flex;
      gap: 6px;
      align-items: center;
    }
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 0.2px solid #a5a6a7;
    }
  }

  .last-step {
    .timer-span {
      display: block;
      color: #1d2023;
      font-family: Poppins;
    }
    h2 {
      &.ant-typography {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
      }
    }
    h5 {
      &.ant-typography {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .ant-typography {
      color: var(--Gray-800, #1d2023);
      font-family: Poppins;

      strong {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px; /* 120% */
      }
    }
  }

  // .ant-switch {
  //   &.ant-switch-checked {
  //     background-color: #ffc261;
  //     height: 24px;
  //     width: 40px;
  //   }
  //   &.ant-switch-handle {
  //     background-color: #fff;
  //   }
  //   .ant-switch-handle {
  //     height: 20px;
  //     width: 20px;
  //     &::before {
  //       border-radius: 50%;
  //     }
  //   }
  // }
  .ant-input,
  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-selection-search-input {
    font-size: 24px;
  }

  .vi__container {
    .vi__character {
      height: 60px;
    }
  }

  .verification-input-mobile {
    width: 250px;
  }
}

.onboarding-card {
  flex-wrap: wrap;

  h4,
  h5 {
    &.ant-typography {
      font-family: Poppins;
    }
  }

  span {
    &.ant-typography {
      font-family: Inter;
    }
  }
  .steps-container {
    margin-top: 42px;
    gap: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .step-card {
      border-radius: 16px;
      border: 1px solid var(--Gray-10, #e6e6e6);
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 320px;
      width: 240px;
      padding: 20px;
      justify-content: space-between;
      font-family: Inter;
      .span-step {
        display: flex;
        align-items: center;
        height: 44px;
      }
      h5 {
        margin-top: 0;
      }

      &.current {
        border: 1px solid var(--Primary-500, #ffc261);
      }

      &.upcoming {
        background: #f9f4e9;
        border: 0;
        color: #999999;
        h5 {
          color: #999999;
          font-family: Poppins;
        }
      }

      .main-image {
        height: 150px;
        width: 150px;
        object-fit: contain;
      }
      .box-with-svg {
        display: flex;
        align-items: center;
        gap: 6px;
        svg {
          path {
            stroke: #1d2023e0;
          }
        }
      }
    }
  }
}
