.book-collab-modal {
  .ant-modal-header {
    margin-bottom: 0;
  }
  .ant-modal-title {
    color: var(--Gray-90, #1d2023);

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }
  .limit {
    width: 472px;
    background: #ffac39;
    margin: 32px 0 0 -24px;
    padding: 20px 24px;
    color: var(--Gray-90, #1d2023);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
  .profile {
    margin: 32px 0;
    display: flex;
    svg {
      width: 12px;
      height: 12px;
    }
    .name {
      color: var(--Gray-90, #1d2023);
      display: block;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 16px;
      .social-container {
        display: flex;
        gap: 4px;
        .social {
          display: flex;
          align-items: center;
          gap: 2px;
          color: var(--Gray-90, #1d2023);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }
      }
    }
  }

  .dates {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .date {
      cursor: pointer;
      display: flex;
      padding: 14px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid var(--Gray-10, #e6e6e6);
      &.selected {
        border: 2px solid var(--Other-Orange, #ffac39);
      }
      &.expired {
        opacity: 0.5;
      }
    }
  }
  .feedback {
    width: 472px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 32px -24px;
    padding: 20px 24px;
    .ant-typography {
      color: var(--Gray-90, white);
    }
    &.success {
      margin: 32px 0 32px -24px;
      background-color: #e2f2dc;
      .ant-typography {
        color: var(--Gray-90, black);
      }
    }
    &.error {
      background-color: #e24354;
    }
  }
  .footer {
    display: flex;
    gap: 16px;
    margin-top: 32px;
    button {
      flex: 1;
    }
  }
}
